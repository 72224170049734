const fontFamily = ["Meiryo UI", "Hiragino Sans", "Hiragino Kaku Gothic ProN","Yu Gothic UI", "MS PGothic", "sans-serif"].join(', ')

export default {
    colors: {
        text: '#284368',
        background: '#f9f9f9',
    },
    styles: {
        body: {
            margin: 0,
            backgroundColor: '#6ec4c1',
            fontFamily: fontFamily
        },
        a: {
            color: '#6d7f98',
        }
    }
}